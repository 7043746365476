<template>
  <v-select
    class="mx-1 shrick text-truncate"
    solo
    return-object
    :multiple="multiple"
    :label="label"
    :suffix="
      multiple
        ? `${suffix} ${selectedItems.length} de  ${items.length}`
        : suffix
    "
    v-model="selectedItems"
    :items="items"
    :item-text="text"
    :item-id="(item) => item[KeyItemId]"
    ref="refAudicolAreas"
  >
    <template v-if="multiple" v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="selectedItems.length > 0 ? 'primary' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Seleccionar todos
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  props: [
    "items",
    "keyItemText",
    "keyItemId",
    "suffix",
    "label",
    "type",
    "multiple",
  ],
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    likeAllItems() {
      return this.selectedItems.length === this.items.length;
    },
    likeSomeItems() {
      return this.selectedItems.length > 0;
    },
    icon() {
      if (this.likeAllItems) return "mdi-close-box";
      if (this.likeSomeItems) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likeAllItems) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.items.slice();
        }
      });
    },
    text(item) {
      // console.log(item[this.keyItemText]);

      if (item && item[this.keyItemText]) {
        return item[this.keyItemText].toUpperCase();
      }
      return null;
    },
  },
  watch: {
    selectedItems(items) {
      switch (this.type) {
        case "Areas":
          {
            this.$emit("seleccionarTiposDocumentalesDeArea", items);
          }
          break;
        case "DocumentaryTypes":
          {
            this.$emit("seleccionarTipoDocumental", items);
          }
          break;
        case "Users":
          {
            this.$emit("seleccionarUsuario", items);
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
