<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        solo
        v-model="dateRangeText"
        suffix="Rango de fecha"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker locale="es" no-title v-model="dates" range></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dates: [],
      menu: false,
    };
  },
  created() {
    this.dates = [
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date())
        .add(1, "days")
        .format("YYYY-MM-DD"),
    ];
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" - ");
    },
  },

  watch: {
    dates(val) {
      this.$emit("fecha", val);
    },
  },
};
</script>
