<template>
    <v-app>
        <v-app-bar app dark color="indigo darken-4">
           <v-toolbar-title>
               Reportes audicol
           </v-toolbar-title>
        </v-app-bar>
        <v-main class="ma-15">
            <v-row v-if="password=='audic0l2022!!!'">
                <v-col cols="12">
                    <v-card rounded="xl" >
                        <Load :load="load_op" />
                        <v-card-text>
                            <h2 style="text-align:center">Cantidad de ordenes de pago {{amount_op}} </h2>
                            <v-img contain max-width="100" :aspect-ratio="16/9" :src="require('../assets/archivador.png')">

                            </v-img>    
                        </v-card-text>
                        <v-card-actions>
                            <v-btn dark @click="get_op()" block color="indigo darken-4" > Reporte ordenes de pago </v-btn>
                        </v-card-actions>
                        
                        <v-card-actions v-for="(op,index) in ops" :key="index">
                            <v-row >
                                <v-col>
                                    <h4 > 
                                        {{op}}
                                        <!--
                                        OP: {{op.op}} |
                                        Cantidad carpetas: {{op.carpetas}} |
                                        Cantidad imagenes: {{op.cantidad_imagenes}} |
                                        -->
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card rounded="xl" >
                        <Load :load="load_resoluciones" />
                        <v-card-text>
                            <h2 style="text-align:center">Cantidad de resoluciones {{amount_resoluciones}} </h2>
                            <v-img contain max-width="100" :aspect-ratio="16/9" :src="require('../assets/archivador.png')">

                            </v-img>    
                        </v-card-text>
                        <v-card-actions>
                            <v-btn block dark @click="get_resoluciones()" color="indigo darken-4" > Reporte de resoluciones </v-btn>
                        </v-card-actions>
                        
                        <v-card-actions v-for="(resolucion,index) in resoluciones" :key="index">
                            <v-row >
                                <v-col>
                                    <h4>
                                        {{resolucion}}
                         
                                        
                                        <!-- 
                                        Resolucion: {{resolucion.resolucion}} |
                                        Cantidad carpetas: {{resolucion.carpetas}} |
                                        Cantidad imagenes: {{resolucion.cantidad_imagenes}} |
                                        --->
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                                           
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card rounded="xl" >
                        <Load :load="load_ica" />
                        <v-card-text>
                            <h2 style="text-align:center">Cantidad de ica {{amount_ica}} </h2>
                            <v-img contain max-width="100" :aspect-ratio="16/9" :src="require('../assets/archivador.png')">

                            </v-img>    
                        </v-card-text>
                        <v-card-actions>
                            <v-btn block dark @click="get_ica()" color="indigo darken-4" > Reporte de ica </v-btn>
                        </v-card-actions>
                       
                        <v-card-actions v-for="(resolucion,index) in ica" :key="index" >
                            <v-row >
                                <v-col>
                                    <h4> 
                                        {{resolucion}}
                                        <!--
                                        Nit: {{resolucion.nit}} |
                                        Cantidad carpetas: {{resolucion.carpetas}} |
                                        Cantidad imagenes: {{resolucion.cantidad_imagenes}} |
                                        -->
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                        
                    </v-card>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-row v-for="(ipu,index) in available_searchs" :key="index" >
                        <v-col>
                            <v-card>
                                <h3 style="text-align:left">
                                Fecha:  {{ipu.date}}
                                </h3>
                                <h3 style="text-align:left">
                                    Referencia:  {{ipu.reference}}
                                </h3>
                                <h3 style="text-align:left">
                                    Consecutivo:  {{ipu.consecutive}}
                                </h3>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            
            <v-dialog v-model="dialog_acces" max-width="400">
                <v-card>
                    <v-toolbar dark color="indigo darken-4">
                        <v-toolbar-title>
                            Compruebe su identidad
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <p>Activar el backup significa que se tomarán los ultimos datos del reporte generados por ti como punto 

                                de inicio el nuevo reporte (Si hace esto pueda que no tenga los datos exactos)
                            </p>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox prepend-icon="mdi-reload blue--text text--darken-3" label="Activar BackUp? " v-model="activate_backup" >

                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field prepend-icon="mdi-lock blue--text text--darken-3" label="Ingrese la clave del módulo" v-model="password" filled rounded dense ></v-text-field>
                            </v-col>
                            
                        </v-row>
                        
                        
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
         <!---
        <v-btn @click="op()"> OP </v-btn>
        <v-btn @click="aumentar()"> Resolucion </v-btn>
       
        <EnvioDatosEmpalme />
        --->
    </v-app>
</template>
<script>
//import EnvioDatosEmpalme from '../components/demo/EnvioDatosEmpalme.vue'
export default {
    components: {
        //EnvioDatosEmpalme
    },
    data(){
        return {
            meta_data: [],
            amount_op: 0,
            amount_resoluciones: 0,
            amount_ica: 0,
            password: "",
            dialog_acces: false,
            load_op: false,
            load_resoluciones: false,
            load_ica: false,
            activate_backup: 0,

            available_searchs: [],

            ops: [],
            resoluciones: [],
            ica: [],
        }
    },
    watch: {
        password(val){
            if(val=='audic0l2022!!!'){
                this.dialog_acces = false;
            }
        }
    },
    async mounted(){
        /*
        const response = await this.$store.dispatch('llamado_get',{
            url: "https://audidoc.educandote.co/types/collections/pruebaOrdendepago/documents/50",
            tipo_header: "indexador"
        });

        console.log(response);
         let contador = 0;
         for (let index2 = 0; index2 < response.data.carpetas.length; index2++) {
            let carpetas = JSON.parse(response.data.carpetas[index2]);
            console.log(carpetas);
            
            if(carpetas.estado=='habilitada' || typeof carpetas.estado ==  'undefined' ){
                contador = contador + carpetas.archivos.length;
                //this.amount_resoluciones = contador;
            }
           
        }

           

        console.log(this.ops);
        */
        /*
        let ipu = await this.$store.dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/types/collections/pruebaExpedientedecontribuyentepredial",
                tipo_header: "indexador",
            });
        console.log(ipu);
         for (let index = 0; index <ipu.data.num_documents; index++) {
                try {
                    let ipu2 = await this.$store.dispatch('llamado_get',{
                        url: "https://audidoc.educandote.co/types/collections/pruebaResolucion/documents/"+index,
                        tipo_header: "indexador",
                    })
                    console.log(ipu2);
                    
                    this.available_searchs.push({
                        date: ipu2.data.fecha,
                        consecutive: ipu2.data.Numero_consecutivo,
                        reference: ipu2.data.Referencia_del_concepto_del_acto_administrativo
                    })
                } catch (error) {
                    console.log(error)
                }
                
        }
        */
        /*
        const date = await this.$store.dispatch('utils/GENERATE_FORMAT_DATE');
        let update_amount_initial = await this.$store.dispatch('llamado_post',
            {url: 'https://audidoc.educandote.co/parse/classes/prueba01AmountsReportes',
                body: {
                    "tipo_documental": "OP",
                    "cantidad_imagenes": 818055,
                    "fecha": date,
                    "fecha_int": Date.parse(new Date(date)),
                    "initial": 10387
                },
                tipo_header: 'parse'
            });
            console.log(update_amount_initial);
        */
        //window.localStorage.setItem('amount_op_initial',"818055");
        //window.localStorage.setItem('index_initial_op',"10387");
    },
    async created(){
        this.dialog_acces = true;
        /*
        try {
            let exp_dots = new RegExp('[.]', 'g');
            let exp_dash = new RegExp('[-]','g');
            let validate_ref =  new RegExp("(Ref|REF|ref)(\\s|[.])", 'g');
            let cajas = [1814,1815,1816,1817,1818,1819,1820,1821,1822,1823,1824,1825,1826,1827,1828,1829,1830,1831,1832,1833,1834,1835,1836,1837,1838,1839,1840,1841,1842,1843,1844,1845,1846,1847,1848,1849,1850,1851,1852,1853,1854,1855,1856,1857,1858,1859,1860,1861,1862,1863,1864,1865,
            1866,1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878,1879,1880,1881,1882,1883];
            let amount_documents = 0


            let documents_to_fix = []
            for (let index = 0; index < cajas.length; index++) {
                
                let first_response = await this.found_documents_page({page:1,caja:cajas[index]})
                console.log(first_response)
                let amount_pages =  parseInt(first_response.data.found/250)+1
                
                console.log(amount_pages)

                for (let page = 1; page <= amount_pages; page++) {
                   let response = await this.found_documents_page({page:page,caja:cajas[index]});
                   for (let index3 = 0; index3 < response.data.hits.length; index3++) {
                       amount_documents = amount_documents + response.data.hits[index3].document.datos_archivos.length
                       let validate_dots = response.data.hits[index3].document.Referencia_del_concepto_del_acto_administrativo.includes('.');
                       let validate_consecutive = response.data.hits[index3].document.Numero_consecutivo.charAt(0)
                     
                       let validate_dash = false
                       let ward_validate_ref = []

                        ward_validate_ref = response.data.hits[index3].document.Referencia_del_concepto_del_acto_administrativo.match(validate_ref)
                        
                       
                        console.log(ward_validate_ref)
                        if(ward_validate_ref!=null){
                             if(ward_validate_ref.length>0){
                                validate_dash = response.data.hits[index3].document.Referencia_del_concepto_del_acto_administrativo.includes('-')

                            }
                        }
                       if(validate_consecutive=='0' || validate_dots==true || validate_dash == true){
                           if(validate_dash){
                               let new_reference2 = response.data.hits[index3].document.Referencia_del_concepto_del_acto_administrativo.replace(exp_dash,'')
                               response.data.hits[index3].document.Referencia_del_concepto_del_acto_administrativo = new_reference2
                           }
                           if(validate_dots){
                               let new_reference = response.data.hits[index3].document.Referencia_del_concepto_del_acto_administrativo.replace(exp_dots,'')
                               response.data.hits[index3].document.Referencia_del_concepto_del_acto_administrativo = new_reference 
                           }
                           if(validate_consecutive=='0'){
                               let consecutive_number = Number(response.data.hits[index3].document.Numero_consecutivo)
                               response.data.hits[index3].document.Numero_consecutivo = consecutive_number.toString()
                           }
   
                           documents_to_fix.push(response.data.hits[index3].document)
                       }
                   }
                }

            }
            console.log(documents_to_fix)
            console.log(amount_documents)
            
        } catch (error) {
            console.log(error)
        }    
        */
        
    },
    methods: {
        probar(){
            this.$store.dispatch('SET_API_DOCUMENTOS_SUBIDOS')
        },
        async get_resoluciones(){
            this.load_resoluciones = true;
            let resoluciones = await this.$store.dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/types/collections/pruebaResolucion",
                tipo_header: "indexador",
            });
             console.log(resoluciones);
            let contador, index_initial
            if(this.activate_backup){
                if(window.localStorage.getItem('amount_resoluciones_initial1')==null || window.localStorage.getItem('index_initial_resoluciones1') == null ){
                    contador = 0;
                    index_initial = 0
                }else{
                    contador = parseInt(window.localStorage.getItem('amount_resoluciones_initial1'));
                    index_initial = parseInt(window.localStorage.getItem('index_initial_resoluciones1'));
                }
            }else{
                contador = 0;
                index_initial = 0
            }
            
            
            for (let index = index_initial; index < resoluciones.data.num_documents; index++) {
                try {
                    let op2 = await this.$store.dispatch('llamado_get',{
                        url: "https://audidoc.educandote.co/types/collections/pruebaResolucion/documents/"+index,
                        tipo_header: "indexador",
                    })
                    let contador_resoluciones = 0;
                    for (let index2 = 0; index2 < op2.data.carpetas.length; index2++) {
                        let carpetas = JSON.parse(op2.data.carpetas[index2]);

                        if(carpetas.estado == 'habilitada' || typeof carpetas.estado == 'undefined' ){
                            contador = contador + carpetas.archivos.length;
                            contador_resoluciones = contador_resoluciones + carpetas.archivos.length;
                            this.amount_resoluciones = contador;
                        }
                            
                    }

                    delete op2.data.carpetas;
                    delete op2.data.datos_archivos;
                    delete op2.data.id;
                   const obj = {
                        cantidad_imagenes: contador_resoluciones,
                        ...op2.data,

                        //resolucion: op2.data.Numero_consecutivo,
                        //carpetas: op2.data.carpetas.length
                    }
                    this.resoluciones.push(obj);
                } catch (error) {
                    console.log(error)
                }
                
            }
            window.localStorage.setItem('amount_resoluciones_initial1',this.amount_resoluciones.toString());
            window.localStorage.setItem('index_initial_resoluciones1',resoluciones.data.num_documents.toString());
            this.load_resoluciones = false; 
            this.$store.commit(
                "exitoso",
                "Reporte de ordenes de resoluciones terminado"
                );
        },
       async get_op(){
           this.load_op = true;
            let op = await this.$store.dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/types/collections/pruebaOrdendepago",
                tipo_header: "indexador",
            })
            /*
            let amounts_initial = await this.$store.dispatch('llamado_get',{
                url: "",
                tipo_header: "parse"
            })
            console.log(amounts_initial);
            */
            console.log(op)

            let contador, index_initial
            if(this.activate_backup){
                if(window.localStorage.getItem('amount_op_initial')==null || window.localStorage.getItem('index_initial_op') == null ){
                    contador = 0;
                    index_initial = 0
                }else{
                    contador = parseInt(window.localStorage.getItem('amount_op_initial'));
                    index_initial = parseInt(window.localStorage.getItem('index_initial_op'));
                }
            }else{
                contador = 0;
                index_initial = 0
            }
            
            
            for (let index = index_initial; index < op.data.num_documents; index++) {
                try {
                    let op2 = await this.$store.dispatch('llamado_get',{
                        url: "https://audidoc.educandote.co/types/collections/pruebaOrdendepago/documents/"+index,
                        tipo_header: "indexador",
                    });

                    let contador_op = 0;
                    
                    for (let index2 = 0; index2 < op2.data.carpetas.length; index2++) {
                        let carpetas = JSON.parse(op2.data.carpetas[index2]);
                        if(carpetas.estado=='habilitada' || typeof carpetas.estado ==  'undefined' ){
                            contador = contador + carpetas.archivos.length;
                            contador_op = contador_op + carpetas.archivos.length;
                
                             this.amount_op = contador;
                        }     
                    } 
                    delete op2.data.carpetas;
                    delete op2.data.datos_archivos;
                    delete op2.data.id;
                    const obj = {
                        cantidad_imagenes: contador_op,
                        ...op2.data,
                        //op: op2.data.Numero_de_orden,
                        //carpetas: op2.data.carpetas.length
                    }
                    this.ops.push(obj);
                } catch (error) {
                    console.log(error)
                }
            }
            window.localStorage.setItem('amount_op_initial',this.amount_op.toString());
            window.localStorage.setItem('index_initial_op',op.data.num_documents.toString());

            /*
            const date = await this.$store.dispatch('utils/GENERATE_FORMAT_DATE');
            let update_amount_initial = await this.$store.dispatch('llamado_post',
                {url: 'https://audidoc.educandote.co/parse/classes/prueba01AmountsReportes',
                    body: {
                        "tipo_documental": "OP",
                        "cantidad_imagenes": this.amount_op,
                        "fecha": date,
                        "fecha_int": Date.parse(new Date(date)),
                        "index_inicio": op.data.num_documents
                    },
                    tipo_header: 'parse'
                });
                console.log(update_amount_initial);
                console.log('OP: '+contador);
            */
           this.load_op = false;
           this.$store.commit(
              "exitoso",
              "Reporte de ordenes de Ordenes de pago terminado"
            );
        },
        async get_ica(){
            
            this.load_ica = true;
            let op = await this.$store.dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/types/collections/pruebaExpedientedecontribuyenteica",
                tipo_header: "indexador",
            })
            /*
            let amounts_initial = await this.$store.dispatch('llamado_get',{
                url: "",
                tipo_header: "parse"
            })
            console.log(amounts_initial);
            */
            console.log(op)

            let contador, index_initial
            if(this.activate_backup){
                if(window.localStorage.getItem('amount_ica_initial')==null || window.localStorage.getItem('index_initial_ica') == null ){
                    contador = 0;
                    index_initial = 0
                }else{
                    contador = parseInt(window.localStorage.getItem('amount_ica_initial'));
                    index_initial = parseInt(window.localStorage.getItem('index_initial_ica'));
                }
            }else{
                contador = 0;
                index_initial = 0
            }
            
            
            for (let index = index_initial; index < op.data.num_documents; index++) {
                try {
                    let op2 = await this.$store.dispatch('llamado_get',{
                        url: "https://audidoc.educandote.co/types/collections/pruebaExpedientedecontribuyenteica/documents/"+index,
                        tipo_header: "indexador",
                    });

                    let contador_ica = 0;
                    
                    for (let index2 = 0; index2 < op2.data.carpetas.length; index2++) {
                        let carpetas = JSON.parse(op2.data.carpetas[index2]);
                        if(carpetas.estado=='habilitada' || typeof carpetas.estado ==  'undefined' ){
                            contador = contador + carpetas.archivos.length;
                            contador_ica = contador_ica + carpetas.archivos.length;
                             this.amount_ica = contador;
                        }     
                    } 
                    delete op2.data.carpetas;
                    delete op2.data.datos_archivos;
                    delete op2.data.id;
                    const obj = {
                        cantidad_imagenes: contador_ica,
                        ...op2.data,
                        //nit: op2.data.Nit_o_docucumento_de_identificacion,
                        //carpetas: op2.data.carpetas.length,
                    }
                    this.ica.push(obj);
                } catch (error) {
                    console.log(error)
                }
            }
            window.localStorage.setItem('amount_ica_initial',this.amount_ica.toString());
            window.localStorage.setItem('index_initial_ica',op.data.num_documents.toString());

            /*
            const date = await this.$store.dispatch('utils/GENERATE_FORMAT_DATE');
            let update_amount_initial = await this.$store.dispatch('llamado_post',
                {url: 'https://audidoc.educandote.co/parse/classes/prueba01AmountsReportes',
                    body: {
                        "tipo_documental": "OP",
                        "cantidad_imagenes": this.amount_op,
                        "fecha": date,
                        "fecha_int": Date.parse(new Date(date)),
                        "index_inicio": op.data.num_documents
                    },
                    tipo_header: 'parse'
                });
                console.log(update_amount_initial);
                console.log('OP: '+contador);
            */
           this.load_ica = false;
           this.$store.commit(
              "exitoso",
              "Reporte de ICA ha terminado"
            );
        },
        async found_documents_page({page,caja}){
            let coleccion = "pruebaResolucion";
            let response = await this.$store.dispatch('llamado_get',{
                url: "https://audidoc.educandote.co/types/collections/"+coleccion+"/documents/search?q="+caja+"&query_by="+'ST-Caja'+"&filter_by="+'ST-Caja'+":["+caja+"]&per_page=250&page="+page             
                ,tipo_header: "indexador"
            });
            console.log(response)
            return response
        },
        aumentar(){
            this.$store.commit('SET_CANTIDAD_DOCUMENTOS_SUBIDOS',1)
        }
    }
}
</script>