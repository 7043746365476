<template>
  <v-app>
    <v-main>
      <v-card :loading="loadReport" class="pa-4 ma-5 " elevation="0">
        <v-card-title>Modulo de Reporte v2</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column mt-4 flex-wrap justify-space-around">
            <RangoFechas :label="'Rango de fecha:'" @fecha="setDates($event)" />

            <SelectAll
              :items="Areas"
              :type="'Areas'"
              :keyItemText="'nombre'"
              :keyItemId="'objectId'"
              :suffix="'Areas'"
              :multiple="false"
              :label="'Seleccionar areas'"
              @seleccionarTiposDocumentalesDeArea="
                seleccionarTiposDocumentalesDeArea($event)
              "
            ></SelectAll>

            <SelectAll
              :items="DocumentaryTypes"
              :type="'DocumentaryTypes'"
              :multiple="false"
              :keyItemText="'nombre'"
              :keyItemId="'objectId'"
              :suffix="'Tipo Documental'"
              :label="'Selecciona el tipo documental'"
              @seleccionarTipoDocumental="seleccionarTipoDocumental($event)"
            ></SelectAll>

            <SelectAll
              :items="Users"
              :type="'Users'"
              :multiple="true"
              :keyItemText="'nombre'"
              :keyItemId="'objectId'"
              :suffix="'Usuarios'"
              :label="'Seleccionar usuarios'"
              @seleccionarUsuario="seleccionarUsuario($event)"
            ></SelectAll>
          </div>
          <div class="d-flex flex-wrap">
            <div>
              <v-btn
                color="primary"
                :disabled="activeBoton"
                @click="crearReporte"
                >OBTENER REPORTE</v-btn
              >
            </div>
            <vue-excel-xlsx
              :data="reportData"
              :columns="reportColumns"
              :file-name="'Reporte'"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
            >
              <v-btn text color="green darken-4" class="mt-1">
                <span>Descargar</span>
                <v-icon>
                  mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </vue-excel-xlsx>
          </div>
          <div class="d-flex flex-wrap justify-space-around ma-4">
            <v-card elevation="0">
              <v-card-title>
                Cantidad de OPs: {{ documentCount }}
              </v-card-title>
            </v-card>
            <v-card elevation="0">
              <v-card-title>
                Cantidad de Carpetas: {{ foldersCount }}
              </v-card-title>
            </v-card>
            <v-card elevation="0">
              <v-card-title>
                Cantidad de Imagenes: {{ imgCount }}
              </v-card-title>
            </v-card>
            <v-card elevation="0">
              <v-card-title>
                Porcentaje: {{ porcentaje.toFixed(1) }}%
              </v-card-title>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>
<script>
import RangoFechas from "../components/atomos/RangoFechas.vue";
import VueExcelXlsx from "vue-excel-xlsx";
import SelectAll from "../components/atomos/SelectAll_v2.vue";
import Vue from "vue";
import pLimit from "p-limit";
import axios from "axios";
import moment from "moment";

const limiter = pLimit(10);
const limiterA = pLimit(10);
Vue.use(VueExcelXlsx);

export default {
  components: {
    RangoFechas,
    SelectAll,
  },
  data() {
    return {
      fecha: {
        inicial: null,
        final: null,
      },
      cantidadImagenes_: 0,

      porcentaje: 0,
      load: null,

      contador_placas: 0,
      contador_tramites: 0,
      contador_imagenes: 0,

      //index: 0,
      reporte: [],
      users_selected: [],
      users: [],
      columns: [
        { label: "Placa", field: "placa" },
        { label: "Número de tramites", field: "tramites" },
        { label: "Número de imagenes", field: "imagenes" },
        { label: "Detalle de los tramites", field: "detalle_tramites" },
      ],
      columnsD: [],
      tenants_available: ["AUDICOL"],
      tenants_selected: [],
      search_tenants_selected: false,
      load_users: false,
      /* Eduard */
      //Report
      reportColumns: [],
      reportData: [],

      //Loaders
      loadReport: false,

      /* Selects */
      //Items
      Areas: [],
      Users: [],
      DocumentaryTypes: [],

      //selectItems
      selectedUser: [],
      selectedArea: [],
      selectedDocumentalType: [],

      documentCount: 0,
      imgCount: 0,
      foldersCount: 0,

      documentCountArray: 0,
      documentCountArrayA: 0,
      parseParamPERMISSIONS: {},
      parseParamUSER: {},
      totalimg: 0,
      stringDetails: "",
    };
  },
  async created() {
    this.inicializarOpcionesArea();

    // this.tenants_selected = [this.tenants_available[0]];
  },
  computed: {
    activeBoton() {
      return !(
        this.selectedUser.length > 0 &&
        this.selectedArea.length > 0 &&
        this.selectedDocumentalType.length > 0
      );
    },
    activeBotonExcel() {
      return !(
        this.selectedUser.length > 0 &&
        this.selectedArea.length > 0 &&
        this.selectedDocumentalType.length > 0
      );
    },
  },
  methods: {
    async crearReporte() {
      const tiempoInicial = performance.now();
      this.reportColumns = [];
      this.reportData = [];

      try {
        this.loadReport = true;
        console.log("Crear Reporte");

        const autores = this.tryObtenerPropiedadDeArrayDeObjeto(
          this.selectedUser,
          "nombre"
        );

        const data = await this.obtenerColeccionDocumental(autores, [1]);
        const found = data.founded;
        console.log(`Hay ${found} cajas/lotes .`);
        this.documentCount = found;
        const arrayPaginacion = this.obtenerArrayDePaginacion(found);

        console.log("Obtener todas las cajas/lotes.");
        const { results } = await this.obtenerColeccionDocumental(
          autores,
          arrayPaginacion
        );

        console.log("Todos las cajas/lotes que encontre: ", results);

        if (results.length > 0) {
          this.rellenarEncabezadoDeReporte(results[0].document, autores);

          for (const { document } of results) {
            this.reportData.push({
              ...document,
              cantidadImagenes: 0,
              digitalizadores: "",
            });
          }
          const carpetas = await this.obtenerColeccionCarpetas(this.reportData);

          console.log(
            "Obtener imaganes habilitadas de las cajas->carpeta: ",
            carpetas
          );

          const imagenes = await this.obtenerColeccionImagenes(
            autores,
            this.reportData
          );

          console.log("Se contaron todas las imagenes.");
        }
      } catch (error) {
        console.trace(error.message);
      } finally {
        this.loadReport = false;
      }
      const tiempoFinal = performance.now();
      console.log(
        `El tiempo de ejecución fue de ${(tiempoFinal - tiempoInicial) /
          60000} minutos`
      );
      this.porcentaje = 100;
    },
    async obtenerColeccionImagenes(autores, documents) {
      const result = [];
      console.log(autores, documents);

      for (const { carpetas, id } of documents) {
        for (const { document } of carpetas) {
          try {
            const { data } = await this.$store.dispatch("llamado_get", {
              url: `https://docutest.educandote.co/types/collections/IMAGENES_V1_${this.selectedDocumentalType[0].nombre.replace(
                / /g,
                ""
              )}/documents/search?q=${
                document.id
              }&query_by=id_carpeta&filter_by=estado_imagen:=[habilitada]&per_page=250&sort_by=order:asc`,
              tipo_header: "indexador",
            });

            if (data.found > 0) {
              const index = this.reportData.findIndex((doc) => doc.id === id);
              let {
                count,
                stringDigitalizadores,
              } = await this.obtenerCantidadDeImagenesPorCaja(
                data.hits,
                autores,
                index
              );

              this.imgCount += count;
              this.reportData[index].cantidadImagenes += count;
              this.reportData[index].digitalizadores =
                this.reportData[index].digitalizadores + stringDigitalizadores;

              this.porcentaje = (index / this.documentCount) * 100;

              console.log({
                Index: index,
                Orden: documents[index].Numero_de_orden,
                Caja: documents[index]["ST-Caja"],
                Carpeta: documents[index]["ST-Carpeta"],
                CantidadPaginas: count,
                Url: `https://docutest.educandote.co/types/collections/IMAGENES_V1_${this.selectedDocumentalType[0].nombre.replace(
                  / /g,
                  ""
                )}/documents/search?q=${
                  document.id
                }&query_by=id_carpeta&filter_by=id_carpeta:[${
                  document.id
                }]&&estado_imagen:=[habilitada]&per_page=250&sort_by=order:asc`,
              });
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }

      return result;
    },

    //PHPRequest
    async contarPaginasPDF(folder, archivo) {
      var formData = new FormData();
      formData.append("texto", folder);
      formData.append("nombre", archivo);
      const config = {
        url: "https://drive.educandote.co/audidoc/countPDF.php",
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const { data } = await axios(config);

        return data.message == "" ? 0 : parseInt(data.message);
      } catch (error) {
        // return error;
        return 0;
      }
    },
    //ParseRequests
    async obtenerAreas() {
      const config = {
        url: `https://audidoc.educandote.co/parse/classes/prueba01Sector`,
        tipo_header: "parse",
      };

      const {
        data: { results: AREAS },
      } = await this.$store.dispatch(
        "llamado_get",
        this.ParseURLParams({ config })
      );

      return { AREAS };
    },
    async obtenerPermisosUsurios({ parseParam = {} }) {
      const config = {
        url: `https://audidoc.educandote.co/parse/classes/prueba01PermisosUsuario`,
        tipo_header: "parse",
      };
      const {
        data: { results: AREA_PERMISSIONS },
      } = await this.$store.dispatch(
        "llamado_get",
        this.ParseURLParams({ parseParam, config })
      );

      return { AREA_PERMISSIONS };
    },
    async obtenerUsuarios({ parseParam = {} }) {
      const config = {
        url: `https://audidoc.educandote.co/parse/classes/prueba03Usuarios`,
        tipo_header: "parse",
      };

      const {
        data: { results: USER },
      } = await this.$store.dispatch(
        "llamado_get",
        this.ParseURLParams({ parseParam, config })
      );

      return { USER };
    },

    //TypesenseRequest
    /**
     * ? Obtienen todas las ordenes/cajas del tipo documental y usuarios seleccionados.
     * @param {*} USUARIOS  --> Usuarios del area seleccionada
     * @param {*} page --> Paginacion
     */
    async obtenerColeccionDocumental_v1(autores, page = 1) {
      let fecha = {
        inicial: Date.parse(new Date(this.fecha.inicial)),
        final: Date.parse(new Date(this.fecha.final + " 23:59")),
      };

      const { data } = await this.$store.dispatch("llamado_get", {
        url: `https://docutest.educandote.co/types/collections/V1_${this.selectedDocumentalType[0].nombre.replace(
          / /g,
          ""
        )}/documents/search?q=${"HABILITADA"}&query_by=estatus&filter_by=fecha_creacion_int:[${
          fecha.inicial
        }..${fecha.final}]&&autor:=[${autores}]&per_page=250&page=${page}`,
        tipo_header: "indexador",
      });

      return data;
    },
    async obtenerColeccionDocumental(autores, pages = [1]) {
      let results = [];
      let founded = 0;

      let fecha = {
        inicial: Date.parse(new Date(this.fecha.inicial)),
        final: Date.parse(new Date(this.fecha.final + " 23:59")),
      };

      await Promise.all(
        pages.map(async (page) => {
          try {
            const { data } = await limiter(() =>
              this.$store.dispatch("llamado_get", {
                url: `https://docutest.educandote.co/types/collections/V1_${this.selectedDocumentalType[0].nombre.replace(
                  / /g,
                  ""
                )}/documents/search?q=${"HABILITADA"}&query_by=estatus&filter_by=fecha_creacion_int:[${
                  fecha.inicial
                }..${
                  fecha.final
                }]&&autor:=[${autores}]&per_page=250&page=${page}`,
                tipo_header: "indexador",
              })
            );

            founded = data.found;

            const { hits } = data;
            results.push(...hits);
          } catch (error) {
            console.error(error.message);
          }
        })
      );

      return { results, founded };
    },
    /**
     * ? Obtiene las carpetas que estan en la caja/lote.
     * @param {*} caja  --> La caja donde vamos a buscar las carpetas
     */
    async obtenerColeccionCarpetasB(documents) {
      let results = [];
      let founded = 0;
      for (const document of documents) {
        console.log("Buscando las carpetas para contar las imagenes...");
        try {
          const { data } = await this.$store.dispatch("llamado_get", {
            url: ` https://docutest.educandote.co/types/collections/CARPETAS_V1_${this.selectedDocumentalType[0].nombre.replace(
              / /g,
              ""
            )}/documents/search?q=${
              document.id
            }&query_by=pertenece_a&per_page=250`,
            tipo_header: "indexador",
          });

          const index = documents.findIndex((doc) => doc.id === document.id);
          founded = data.found;

          const { hits } = data;
          this.foldersCount += founded;

          this.reportData[index].carpetas = hits;
          results.push(hits);
        } catch (error) {
          console.log(error);
        }
      }

      return { results, founded };
    },
    async obtenerColeccionCarpetas(documents) {
      let results = [];
      let founded = 0;
      await Promise.all(
        documents.map(async (document) => {
          try {
            const { data } = await limiter(() =>
              this.$store.dispatch("llamado_get", {
                url: ` https://docutest.educandote.co/types/collections/CARPETAS_V1_${this.selectedDocumentalType[0].nombre.replace(
                  / /g,
                  ""
                )}/documents/search?q=${
                  document.id
                }&query_by=pertenece_a&per_page=250`,
                tipo_header: "indexador",
              })
            );
            console.log("Buscando las carpetas para contar las imagenes...");
            const index = documents.findIndex((doc) => doc.id === document.id);
            founded = data.found;

            const { hits } = data;
            this.foldersCount += founded;

            this.reportData[index].carpetas = hits;
            results.push(hits);
          } catch (error) {
            console.error(error.message);
          }
        })
      );

      return { results, founded };
    },
    /**
     *
     * @param {*} array
     */
    async obtenerColeccionImagenes_v1(array) {
      const arrayDocuments = [];

      await Promise.all(
        array.map(async (value) => {
          try {
            const data = await limiter(() =>
              this.obtenerColeccionDocumental(this.selectedUser, value)
            );
            const { hits } = data;
            this.documentCountArrayA += hits.length;

            arrayDocuments.push(...hits);
          } catch (error) {
            console.error(error.message);
          }
        })
      );
      return arrayDocuments;
    },

    async obtenerCantidadDeImagenesPorCaja(array, autores, index) {
      let count = 0;
      let stringDigitalizadores = "";
      let arrayDigitalizadores = "";
      const limiter_ = pLimit(10);

      await Promise.all(
        array.map(async ({ document }) => {
          try {
            let PDFImages = await limiter(() =>
              this.contarPaginasPDF(
                document.token,
                `${document.nombre}.${document.tipo}`
              )
            );

            stringDigitalizadores =
              stringDigitalizadores +
              `Autor: ${document.autor}|Documento: ${
                document.nombre
              }|Cantidad De Imagenes: ${PDFImages}|Fecha Digitalizacion: ${moment(
                new Date(document.fecha_creacion_int)
              ).format("YYYY-MM-DD")} ;\n`;

            // const index = this.reportData.findIndex((doc) => doc.id === id);
            // this.reportData;

            if (autores.includes(document.autor)) {
              // arrayDigitalizadores =
              //   arrayDigitalizadores +
              //   ` Autor: ${document.autor}|Documento: ${
              //     document.nombre
              //   }|Cantidad De Imagenes: ${PDFImages}|Fecha Digitalizacion: ${moment(
              //     new Date(document.fecha_creacion_int)
              //   ).format("YYYY-MM-DD")} ;\n`;

              this.reportData[index][document.autor] =
                (this.reportData[index][document.autor]
                  ? this.reportData[index][document.autor]
                  : "") +
                `Documento: ${
                  document.nombre
                }|Cantidad De Imagenes: ${PDFImages}|Fecha Digitalizacion: ${moment(
                  new Date(document.fecha_creacion_int)
                ).format("YYYY-MM-DD")} ;\n`;
            }

            count += PDFImages;
          } catch (error) {
            console.error(error.message);
          }
        })
      );

      return { count, stringDigitalizadores, arrayDigitalizadores };
    },

    //Componente de seleccion
    seleccionarTiposDocumentalesDeArea(AREA) {
      this.selectedArea = [AREA];

      this.DocumentaryTypes = [];

      this.parseParamPERMISSIONS = {
        where: { sector: { $in: [] } },
      };

      this.selectedArea.forEach(({ objectId, tipos_documentales }) => {
        this.parseParamPERMISSIONS.where.sector.$in.push(objectId);
        this.DocumentaryTypes.push(...tipos_documentales);
      });

      this.inicializarOpcionesUsuarios();
    },
    seleccionarTipoDocumental(DOCUMENTAL_TYPE) {
      this.selectedDocumentalType = [DOCUMENTAL_TYPE];
    },
    seleccionarUsuario(USUARIO) {
      this.selectedUser = [...USUARIO];
    },

    //Utils
    rellenarEncabezadoDeReporte(document, autores) {
      this.reportColumns.push({
        label: "id".toUpperCase(),
        field: "id",
      });
      for (const [key, value] of Object.entries(document)) {
        if (
          key != "extended_properties" &&
          key != "estatus" &&
          key != "fecha_creacion_int" &&
          key != "fecha_documento_int" &&
          key != "order" &&
          key != "id"
        ) {
          this.reportColumns.push({
            label: key.toUpperCase(),
            field: key,
          });
        }
      }

      this.reportColumns.push({
        label: "CANTIDAD DE IMAGENES",
        field: "cantidadImagenes",
      });
      // this.reportColumns.push({
      //   label: "FECHA DIGITALIZACIÓN",
      //   field: "fechaDigitalizacion",
      // });
      this.reportColumns.push({
        label: "DIGITALIZADORES",
        field: "digitalizadores",
      });
      for (const autor of autores) {
        this.reportColumns.push({
          label: autor,
          field: autor,
        });
      }
    },
    ordenarOpcionesAlfabeticamente(array, atributo) {
      array.sort(function(a, b) {
        if (a[atributo] < b[atributo]) {
          return 1;
        }
        if (a[atributo] > b[atributo]) {
          return -1;
        }
        return 0;
      });

      return array;
    },
    setDates(event) {
      this.fecha.inicial = event[0];
      this.fecha.final = event[1];
    },
    ParseURLParams({ parseParam = {}, config }) {
      if (Object.entries(parseParam).length > 0) {
        config.url = config.url + "?";

        Object.entries(parseParam).forEach(([key, value]) => {
          config.url = config.url + `${key}=${this.URLEnconde(value)}`;
        });
      }

      return config;
    },
    URLEnconde(params) {
      return encodeURIComponent(JSON.stringify(params));
    },
    tryObtenerPropiedadDeArrayDeObjeto(array, propiedad) {
      let result = [];
      try {
        array.forEach((el) => {
          result.push(el[propiedad]);
        });
      } catch (error) {
        console.log(error.message);
      }

      return result;
    },
    /**
     * ? Regresa un array que va a servir para paginar la cantidad de registros encontrados.
     * @param {number} total
     */
    obtenerArrayDePaginacion(total) {
      const array = new Array(Math.ceil(total / 250));
      for (let index = 0; index < array.length; index++)
        array[index] = index + 1;

      return array;
    },
    // Inicializar Opciones
    async inicializarOpcionesUsuarios() {
      //Permisos disponibles para Usuarios del sistema
      const { AREA_PERMISSIONS } = await this.obtenerPermisosUsurios({
        parseParam: this.parseParamPERMISSIONS,
      });

      this.Users = [];
      AREA_PERMISSIONS.forEach(
        async ({ permiso_1: permission, sector: area }) => {
          const { supervisor, empleado } = permission;
          this.parseParamUSER = {
            where: {
              sector: area,
              rango: {
                $in: [],
              },
            },
          };

          if (supervisor)
            this.parseParamUSER.where.rango.$in.push("Supervisor");
          if (empleado) this.parseParamUSER.where.rango.$in.push("Empleado");

          //Usuarios del sistema que cumplen con los permisos disponibles
          const { USER } = await this.obtenerUsuarios({
            parseParam: this.parseParamUSER,
          });
          this.Users.push(...USER);
        }
      );
    },

    async inicializarOpcionesArea() {
      const { AREAS } = await this.obtenerAreas();

      this.Areas = [];
      this.Areas = [...AREAS];
      this.Areas = this.ordenarOpcionesAlfabeticamente(this.Areas, "nombre");
    },
  },
};
</script>
